import accordion from './modules/accordion'
import breadcrumb from './modules/breadcrumb'
import dropdown from './modules/dropdown'
import inlineFunctions from './modules/inlineFunctions'
import inputToogleActive from './modules/inputToogleActive'
import multiLevelMenu from './modules/multiLevelMenu'
import showCorrectSection from './modules/showCorrectSection'
import showToast from './modules/showToast'
import startAllChoices from './modules/startAllChoices'
import tabs from './modules/tabs'
import mostraSenha from './modules/mostraSenha'
import addFilterURLAndReload from './modules/addFilterURLAndReload.js'
import badge from './modules/badge.js'
import modalFiltrar from './modules/modalFiltrar.js'
import dataTablesInit from './modules/dataTablesInit.js'
import tableBasicSearch from './modules/tableBasicSearch'
import tableBasicQnt from './modules/tableBasicQnt'
import tableBasicRecentes from './modules/tableBasicRecentes'
import tableBasicOrdenation from './modules/tableBasicOrdenation'
import radioStyle from './modules/radioStyle'
import loader from './modules/Loader'
// dataTablesInit()
startAllChoices() //inicia bibiloteca Choices.js
accordion()
breadcrumb()
dropdown()
inlineFunctions() // Faz as implementações relacionadas aos inlines
inputToogleActive() // muda class ativa nos inputs
multiLevelMenu()
showCorrectSection() //mostra elemento de acordo com parametro na url
showToast()
tabs()
badge()
mostraSenha()
addFilterURLAndReload() //adiona um filtro na url e recarrega
dataTablesInit()
tableBasicSearch()
tableBasicQnt()
tableBasicRecentes()
tableBasicOrdenation()
radioStyle()

const menu = multiLevelMenu(subMenuItens)?.init()
window.myLoader = loader
window.menu = menu
window.modalFiltrar = modalFiltrar
window.tabs = tabs

document.addEventListener('DOMContentLoaded', () =>
	document.body.classList.add('DOMContentLoaded')
)
